.sizer {
  width: 100%;
  display: inline-grid;
  align-items: stretch;

  &::after,
  textarea {
    grid-area: 1 / 1;
    padding: 0.75rem 0.75rem;
    appearance: none;
  }

  &::after {
    padding: 0.75rem 0.75rem; // same as textarea padding
    border: 1px solid transparent; // same border width as textarea
    content: attr(data-value) ' ';
    visibility: hidden;
    white-space: pre-wrap;
    overflow-wrap: anywhere;
  }
}
