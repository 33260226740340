@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Inter Variable';
  src:
    url('./fonts/inter-variable.woff2') format('woff2 supports variations'),
    url('./fonts/inter-variable.woff2') format('woff2-variations');
  font-weight: 100 900;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src:
    url('./fonts/inter-variable.woff2') format('woff2 supports variations'),
    url('./fonts/inter-variable.woff2') format('woff2-variations');
  font-weight: 100 900;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans Variable';
  src:
    url('./fonts/opensans-variable.woff2') format('woff2 supports variations'),
    url('./fonts/opensans-variable.woff2') format('woff2-variations');
  font-weight: 100 900;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src:
    url('./fonts/opensans-variable.woff2') format('woff2 supports variations'),
    url('./fonts/opensans-variable.woff2') format('woff2-variations');
  font-weight: 100 900;
  font-display: swap;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  max-width: 100%;
  display: flex;
  flex-direction: column;

  overflow-x: hidden;
}
